<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">400</h1>
            <h4 class="pt-3">Данный пользователь заблокирован</h4>
            <p class="text-muted">Данный пользователь заблокирован на этом ресурсе Lukoil.</p>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'BlockedUser'
}
</script>
